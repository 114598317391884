@use "@styles/variables" as variable;

.topSection {
  padding: 40px 0;
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
}
.main {
  text-align: center;
  padding: 20px 20px 50px;
  .img {
    margin-bottom: 20px;
    img {
      margin: 0 auto;
    }
  }
  h1 {
    font-family: variable.$titlesFont;
    display: block;
    font-weight: normal;
    font-size: 25px;
    line-height: 28px;
    color: variable.$primaryColor;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 45px;
  }
  p {
    font-family: variable.$primaryFont;
    color: #262626;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 18px;
  }
}
